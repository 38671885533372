import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"

const CatalogItemPage = () => (
	<Layout>
		<Seo title="Ruby Soho" />

		<h2>Ruby Soho</h2>

		<div className="main--centerwrapper">
			<StaticImage
			src="../../images/catalog artwork/JRIVEST07 ruby soho front.jpg"
			alt="Jesse Rivest - Ruby Soho - cover art"
			placeholder="blurred"
			layout="constrained"
			className="main--centered"
			imgClassName="img--bordered-small"
			width={500}
			/>
		</div>

		<h3>Prerelease note</h3>
		<p>
			To be released on November 18, 2021.
		</p>

		<h3>Words from Jesse</h3>
		<p>
			My first-ever stylized, recorded and released cover song!
			I've actually been playing this song for about a decade.
			In 2011, my friend, Shannon, asked if I could play this Rancid song;
			of course, it wasn't in my repertoire, and it was very far from my style.
			I took that as an opportunity to try it on, and I came up with this version.
			I recorded a simple video of it in Montreal and <a href="//youtu.be/pf9Fie1vUYc">posted it on Youtube</a>.
			I have never released a recorded cover song for legal issues—or shall I say for lack of understanding the legalities of such an endeavour.
			However, in 2021 I came to understand the Music Modernization Act in the USA and how it affected me globally;
			I decided it was my time to release a cover song.
			I may even release some more!
		</p>

		<h3>Track listing</h3>
		<ol>
			<li>
				Ruby Soho (3:25)<br />
				T0702493035 - © I Want to Go Where the Action Is Music 1995 (ASCAP)<br />
				CA-6P8-21-00004 - © Jesse Rivest 2021 (PPL)
			</li>
		</ol>
		<p>
			This recording: catalog number JRIVEST07, © Jesse Rivest 2021, All Rights Reserved<br />
			UPC/EAN: 0198001541285<br />
			Released: November 18, 2021<br />
			Note: CA-6P8-21-00003 was lost in a distributor/Spotify mix-up during an attempt to release a single in October.
			Ruby Soho could have been CA-6P8-21-00003 but, because of the previous error, it became CA-6P8-21-00004.
		</p>

		<h3>Credits</h3>
		<p>
			Produced, recorded, mixed, and mastered by Jesse Rivest at his Little Bakery Studio in Brasilia, DF, Brazil.<br />
			Song sung by Jesse Rivest.<br />
			Acoustic guitar played by Jesse Rivest.<br />
			Album cover photograph by Jesse Rivest.<br />
			Album cover layout by Jesse Rivest.<br />
		</p>

		<h3>Special Thanks</h3>
		<p>
			Thanks to Jane Lino for being supportive, always. Also, she helped me take the cover photo.<br />
			Thanks to Tico de Moraes for lending his ears and his supportive words.
		</p>

		<h3>Extras</h3>

		<h4>Back cover</h4>
		<div className="main--centerwrapper">
			<StaticImage
			src="../../images/catalog artwork/JRIVEST07 ruby soho back.png"
			alt="Jesse Rivest - Ruby Soho - back cover"
			placeholder="blurred"
			layout="constrained"
			className="main--centered"
			imgClassName="img--bordered-small"
			width={500}
			/>
		</div>

		<p>
			<Link to="../">Go back to Music Catalog</Link>
		</p>
	</Layout>
);

export default CatalogItemPage;
